/* ===================== Contact ======================*/

.map-area .section-title {
	text-align: center
}

.map-area iframe {
	width: 100%;
	height: 430px;
}

.contact-form .section-title {
	text-align: center
}

.contact-form-wrapper {
	background: #EEF3FA none repeat scroll 0 0;
	padding: 33px 30px 24px;
	position: relative;
	border-radius: 30px
}

.contact-form-wrapper:before {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	width: 115%;
	height: 100%;
	background: #EEF3FA none repeat scroll 0 0;
	z-index: -1;
	border-radius: 30px
}

.contact-form-wrapper .form-group label {
	color: #000;
	text-align: left;
	display: block;
	font-size: 14px;
	font-weight: 500;
}

.contact-form-wrapper .form-group input {
	width: 100%;
	padding: 5px 15px;
	height: 47px;
	border: 0px solid #e3e3e3;
	border-radius: 3px;
	font-size: 15px;
	background: #fff;
	-webkit-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
}

.contact-form-wrapper .form-group textarea {
	width: 100%;
	padding: 5px 15px;
	height: 140px;
	border: 0px solid #e3e3e3;
	border-radius: 3px;
	font-size: 15px;
	background: #fff;
	-webkit-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
}

.address-area {
	-webkit-box-align: center;
	-ms-flex-align: center;
	-ms-grid-row-align: center;
	align-items: center;
	-ms-flex-item-align: center;
	align-self: center;
	padding: 30px;
	background: url(../../img/carrer_bg.jpg) no-repeat scroll 0 0/cover;
	position: relative;
	z-index: 1;
	border-radius: 30px;
	padding: 22px 30px 25px;
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.address-area:after {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(112, 82, 251, 0.9) none repeat scroll 0 0;
	z-index: -1;
	border-radius: 30px;
}

.address-area .title {
	font-size: 26px;
	font-weight: 600;
	color: #fff;
	position: relative;
	display: inline-block;
	margin-bottom: 20px;
}

.address-area .address-list {
	padding: 22px 0px 0px;
}

.address-area .address-list li {
	margin-bottom: 20px;
}

.address-area .address-list li p {
	color: #ffffff;
	padding-left: 30px;
	position: relative;
	font-size: 16px
}

.address-area .address-list li p svg {
	position: absolute;
	left: 0;
	top: 5px;
	font-size: 20px
}

.form-group.button-area {
	text-align: center
}
