/* ===================== BLOG ======================*/

.blog-section {
	background: #eef3fa none repeat scroll 0 0
}

.blog-section .section-title h2 {
	z-index: 1;
	position: relative
}

.blog-item {
	background: #fff;
	box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
	border-radius: 20px;
	margin-top: 30px
}

.blog-image {
	padding: 10px 10px 0px 10px;
}

.blog-image img {
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
}

.blog-desc {
	padding: 30px;
}

.meta-image {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 0 0 20px;
}

.author-round {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	position: relative;
	overflow: hidden;
}

.meta-image .tags {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	padding-left: 20px;
}

.meta-image .tags h4 {
	color: #1c2045;
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 5px;
}

.meta-image .tags h4 span {
	font-weight: 600;
}

.meta-image .tags h5 {
	color: #6fe7dd;
	font-size: 16px;
	font-weight: 600;
}

.blog-text a h3 {
	color: #1c2045;
	font-weight: 600;
	font-size: 22px;
	letter-spacing: .75px;
	-webkit-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
}

.blog-text a h3:hover {
	color: #6fe7dd;
}
