/* ===================== Blog ======================*/

.blog-item-box {
	margin-bottom: 30px
}

.blog_info .blog-img {
	width: 100%;
	height: auto;
	margin: 0;
	padding: 0;
	position: relative;
}

.blog_info .blog-img img {
	width: 100%;
	height: 100%;
}

.blog_info .blog-detail {
	width: 100%;
	margin: 0;
	padding: 50px;
	border: 2px solid #f5f8fa;
}

.blog-page-left .other_info {
	padding-top: 5px;
	margin-bottom: 20px;
}

.other_info {
	width: 100%;
	margin: 0;
	padding: 25px 0 0 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: relative;
}

.blog-meta {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-line-pack: center;
	align-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.other_info figure {
	width: 40px;
	height: 40px;
	margin: 0 10px 0 0;
	padding: 0;
	border-radius: 50%;
	display: inline-block;
}

.other_info figure img {
	border-radius: 50%;
}

.blog-meta h4 {
	font-size: 16px;
	font-weight: 500;
	color: #111;
}

.other_info label {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	margin: 0;
	-ms-flex-line-pack: center;
	align-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	color: #111;
}

.other_info label a {
	color: #111
}

.blog-page-left .other_info label {
	-webkit-box-flex: 0;
	-ms-flex: 0 auto;
	flex: 0 auto;
	margin-left: 20px;
}

.other_info label svg{
	margin-right: 5px;
	color: #6fe7dd;
}

.blog-detail p {
	margin: 0 0 15px;
}

.blog-page-left .theme-btn {
	margin-top: 20px;
	position: relative;
	top: 5px;
}

.blog-detail h3 {
	font-weight: 600;
	margin-bottom: 20px;
	font-size: 28px;
	color: #1c2045
}

.blog-detail h3 a {
	color: #1c2045
}

.blog-page-left .pagination-box-row {
	text-align: left;
}