/* ===================== Checkout ======================*/

.checkout-left-box h3,
.order-summury-box h3 {
	color: #1c2045;
	font-weight: 700;
	font-size: 26px;
	margin-bottom: 30px;
	position: relative;
	letter-spacing: 0px;
	display: block;
	text-transform: capitalize;
}

.checkout-left-box form {
	padding: 15px 30px 30px 30px;
	background: #eef3fa none repeat scroll 0 0;
	border-radius: 30px;
}

.checkout-left-box form .checkout-form {
	margin-top: 15px;
}

.checkout-left-box form label {
	color: #000;
	text-align: left;
	display: block;
	font-size: 14px;
	font-weight: 500;
}

.checkout-left-box form input {
	width: 100%;
	padding: 5px 15px;
	height: 47px;
	border: 0px solid #e3e3e3;
	border-radius: 3px;
	font-size: 15px;
	background: #fff;
	-webkit-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
}

.checkout-left-box form textarea {
	width: 100%;
	padding: 5px 15px;
	height: 120px;
	border: 1px solid #e3e3e3;
	border-radius: 3px;
	font-size: 15px;
	background: #fff;
	-webkit-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
}

.checkout-left-box form input:focus,
.checkout-left-box form textarea:focus {
	border: 1px solid #8668eb;
}

.booking-payment .payment {
	display: block;
	position: relative;
	float: left;
	width: 100%;
	height: auto;
}

.booking-payment .payment input[type=radio] {
	position: absolute;
	visibility: hidden;
}

.booking-payment .payment label {
	display: inline-block;
	position: relative;
	padding: 0 0 0 30px;
	margin: 10px auto;
	z-index: 9;
	cursor: pointer;
	-webkit-transition: all 0.25s linear;
	color: #111;
	font-weight: 500;
	font-size: 15px;
	font-weight: 500;
}

.booking-payment .payment .check {
	display: block;
	position: absolute;
	border: 3px solid #AAAAAA;
	border-radius: 100%;
	height: 20px;
	width: 20px;
	top: 14px;
	left: 0;
	z-index: 5;
	transition: border .25s linear;
	-webkit-transition: border .25s linear;
}

.booking-payment .payment .check:before {
	display: block;
	position: absolute;
	content: '';
	border-radius: 100%;
	height: 8px;
	width: 8px;
	top: 3px;
	left: 3px;
	margin: auto;
	transition: background 0.25s linear;
	-webkit-transition: background 0.25s linear;
}

input[type="radio"]:checked~.check {
	border: 3px solid #6fe7dd;
}

input[type="radio"]:checked~.check:before {
	background: #6fe7dd;
}

input[type="radio"]:checked~label {
	color: #6fe7dd;
}

.payment img {
	float: right;
	margin-top: 15px;
}

.payment p {
	font-size: 15px;
}

.booking-right .action-btn {
	text-align: right;
	margin-top: 30px
}

.summury-inn {
	padding: 20px;
	background: #eef3fa none repeat scroll 0 0;
	border-radius: 30px;
}

.order-summury-box table {
	width: 100%;
}

.order-summury-box table tr {
	border-bottom: 1px solid #ddd;
}

.order-summury-box table td {
	padding: 10px 0;
	color: #111;
	font-weight: 600;
	font-size: 14px;
}