/* ===================== PARTNER ======================*/

.partners-section .section-title {
	text-align: center;
	margin-bottom: 20px
}

.sponsor-box-item {
	text-align: center;
	margin-top: 30px;
}

.sponsor-box li {
	display: inline-block;
	margin: 0px 15px;
	width: 21%;
}

.sponsor-box li a {
	display: block;
	padding: 20px;
	background: #F2F5FE none repeat scroll 0 0;
	border-radius: 5px
}

.sponsor-box li img {
	width: 140px;
}
