/* ===================== Blog ======================*/

.blog-details-page .blog_info .blog-detail {
	border: medium none;
	padding: 50px 0 0
}

.blog-details-page blockquote {
	margin-top: 34px;
	margin-bottom: 34px;
	border-left: 5px solid #a693fd;
	background-color: #6fe7dd;
	position: relative;
	padding: 30px 35px 38px 35px;
}

.blog-details-page blockquote:before {
	position: absolute;
	content: '';
	left: -5px;
	top: 0;
	width: 0;
	height: 0;
	border-left: 0;
	border-right: 20px solid transparent;
	border-top: 20px solid #fff;
}

.blog-details-page blockquote .blockquote_desc {
	font-style: italic;
	margin-bottom: 0;
	margin-top: 0;
	color: #fff;
	font-size: 18px;
	line-height: 28px;
	font-weight: 500;
}

.blog-details-page blockquote .qoute_meta {
	color: #fff;
	font-size: 20px;
	text-transform: capitalize;
	line-height: 26px;
	font-weight: 600;
	position: relative;
	display: inline-block;
	margin-top: 20px;
	border-top: 1px solid #fff;
}

.blog-details-page blockquote svg {
	color: #a693fd;
	font-size: 45px;
	opacity: .8;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
	position: absolute;
	right: 20px;
	bottom: -20px;
}

.blog-cat {
	margin-top: 30px;
}

.blog-cat a {
	display: inline-block;
	font-size: 14px;
	color: #111;
	background: #EEF3FA;
	border-radius: 30px;
	padding: 9px 20px;
}

.blog-cat a:hover {
	background: #6fe7dd;
	color: #fff;
}

.comment-list {
	margin-top: 50px;
}

.comment-group-title h3,
.leave-comment h3 {
	font-size: 26px;
	margin-bottom: 30px;
	color: #1c2045;
	font-weight: 600;
	position: relative;
	display: block;
	text-transform: capitalize;
}

.single-comment-box {
	margin-bottom: 10px;
	position: relative;
	-webkit-transition: all 300ms ease 0s;
	transition: all 300ms ease 0s;
}

.main-comment {
	font-size: 15px;
	padding: 0 0 0 90px;
	position: relative;
	margin-bottom: 30px;
}

.author-image {
	border-radius: 50%;
	left: 0;
	margin-bottom: 20px;
	position: absolute;
}

.author-image img {
	display: block;
	width: 70px;
	border-radius: 50%;
}

.comment-text {
	padding: 20px;
	background: #EEF3FA;
	border-radius: 30px;
	position: relative;
}

.comment-info:after {
	content: '';
	height: 0;
	width: 0;
	position: absolute;
	display: block;
	border-style: solid;
	top: 25px;
	border-width: 11px 13px 11px 0;
	border-color: transparent #EEF3FA;
	left: -12px;
}

.comment-info h4 {
	display: inline-block;
	color: #1c2045;
	text-transform: capitalize;
	font-size: 18px;
	padding-left: 10px;
	font-weight: 600;
}

.comment-info ul {
	display: inline-block;
	margin: 0 10px;
}

.comment-info ul li {
	display: inline-block;
	color: #ffa11a;
}

.comment-info p {
	display: inline-block;
	font-size: 13px;
}

.comment-text-inner {
	padding: 10px;
}

.single-comment-box.comment_reply {
	padding-left: 83px;
}

.comment-field {
	margin-top: 15px;
}

.leave-comment form {
	padding: 15px 30px 30px 30px;
	background: #EEF3FA none repeat scroll 0 0;
	border-radius: 30px;
}

.leave-comment form label {
	color: #000;
	text-align: left;
	display: block;
	font-size: 14px;
	font-weight: 500;
}

.leave-comment form input {
	width: 100%;
	padding: 5px 15px;
	height: 47px;
	border: 0px solid #e3e3e3;
	border-radius: 3px;
	font-size: 15px;
	background: #fff;
	-webkit-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
}

.leave-comment form textarea {
	width: 100%;
	padding: 5px 15px;
	height: 140px;
	border: 0px solid #e3e3e3;
	border-radius: 3px;
	font-size: 15px;
	background: #fff;
	-webkit-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
}

.leave-comment {
	margin-top: 50px;
}

.leave-comment h3 {
	margin-bottom: 15px
}

.comment-field {
	text-align: center
}

.comment-field .theme-btn {
	margin-top: 0;
}